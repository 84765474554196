import type { IUserButton } from './UserButton'
import { useContext } from 'react'
import { useRouter } from 'next/router'
import { GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_HEADER } from '../../../constants/gigya'
import { setupOriginUrlCookie } from '../../../helpers/cookies'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { useUser } from '../../../hook/useUser'
import { mixins, theme } from '../../../styles'
import { SVGIcon } from '../SVGIcon'

export function UserButtonStandard({ unloggedWording }: IUserButton): JSX.Element {
  const { asPath } = useRouter()
  const { hit } = useContext(TagCommanderContext)
  const { isLoggedIn, loading, userProfile } = useUser()

  const urlRedirectAccount = isLoggedIn
    ? '/compte/'
    : `/compte/accueil/?serviceType=${GIGYA_REGISTRATION_ORIGIN_SERVICE_TYPE_HEADER}`

  const handleAccountClick = () => {
    setupOriginUrlCookie(asPath)
    hit(
      {
        screen_clickableElementName: `header_mon-compte_${isLoggedIn ? '' : 'non'}_logue`,
      },
      { isClickEvent: true },
    )
    window.location.href = urlRedirectAccount
  }

  return (
    <>
      {!loading && (
        <a
          className={`UserButton flex items-center${isLoggedIn ? ' UserButton--connect' : ''}`}
          href="#"
          onClick={handleAccountClick}
          data-testid="btn-account"
        >
          {isLoggedIn && userProfile?.profile?.firstName?.length ? (
            <p className="UserButton__Title">{userProfile.profile.firstName[0].toUpperCase()}</p>
          ) : (
            <SVGIcon
              name="user"
              primaryColor={isLoggedIn ? theme.cssVars.deepBlue : theme.cssVars.white}
              secondaryColor={isLoggedIn ? theme.cssVars.deepBlue : theme.cssVars.focusBlue}
              size="20px"
            />
          )}
          {!isLoggedIn && (
            <p className="UserButton__Unlogged__Title">{unloggedWording ?? 'Se connecter'}</p>
          )}
        </a>
      )}

      <style jsx>{`
        .UserButton {
          cursor: pointer;
          gap: 4px;
          border: 1px solid ${theme.cssVars.white};
          border-radius: 24px;
          padding: 6px;
          color: ${theme.cssVars.white};

          text-decoration: none;
          font-size: 16px;
          height: 32px;
          width: 32px;
        }

        .UserButton--connect {
          color: ${theme.cssVars.deepBlue};
          background-color: ${theme.cssVars.lightGrayBlue};
          padding: 0;
          font-weight: 700;
          justify-content: center;
        }

        .UserButton__Unlogged__Title {
          display: none;
        }

        .UserButton--connect {
          width: 32px;
        }

        .UserButton p {
          padding-top: 4px;
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .UserButton__Unlogged__Title {
            display: block;
          }

          .UserButton:not(.UserButton--connect) {
            padding: 6px 12px;
            width: initial;
          }
        }
      `}</style>
    </>
  )
}
